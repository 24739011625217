import React, { useEffect, useState } from 'react';
import swal from 'sweetalert';
import axios from 'axios';

const SalesForce = (props) => {
    let [ idProyecto, setProyecto] = useState('')
    const [nombre, colocarNombre] = useState("")
    const [apellido, colocarApellido] = useState("")
    const [tel, colocarTel] = useState("")
    const [email, colocarEmail] = useState("")
    let [res,setRes] = useState({});
    const [varHome, setVarHome] = useState(false)
    const [myValue, setMyValue] = useState("a011P00000KRF1GQAX");
    useEffect(()=>{
        const pathname = window.location.pathname;
        // console.log(pathname)
       if(pathname==='/ibiza'){
        setProyecto('a011P00000KRF1VQAX')
       }
       if(pathname==='/montecarlo'){
        setProyecto('a011P00000KRF1GQAX')
       }
       if(pathname==='/palermo'){
        setProyecto('a011P00000KRF1LQAX')
       }
       if(pathname==='/'){
        setVarHome(true)
        setProyecto('a011P00000KRF1GQAX')
       }
    },[])

    const handleSubmit = (e) => {
        //e.preventDefault()
        //console.log(e)
        if(props.titulo === "DESCARGA NUESTRO BROCHURE"){
            var link = document.createElement('a');
            link.href = props.brochure
            link.download = 'Brochure.pdf';
            link.target ='_blank'
            link.dispatchEvent(new MouseEvent('click'));
        }
    }
    const showModalAviso = () =>{
        props.showModal2(true)
        props.showSegundoM('sobreponer')
    }
    const handleSelect = (e) => {
        setMyValue(e.target.value)
        setProyecto(e.target.value)
    }
    return ( 
        <>
            <div className='container__brochure'>
                <div className={`formulario salesForce ${props.nomodal}`}>
                    <h3>{props.titulo}</h3>
                    <form onSubmit={handleSubmit} action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8" method="POST">
                        {/* <form> */}
                        <input type={"hidden"} name="oid" value="00D1a000000Z053"/>
                        <input type={"hidden"} name="retURL" value="https://davivir.com.mx/gracias"/> 
                        <div className='row'>
                            <label htmlFor="first_name">Nombre</label>
                            <input required id="first_name" maxLength="40"  name="first_name" size="20" type="text" />
                        </div>
                        <div className='row'>
                            <label htmlFor="last_name">Apellidos</label>
                            <input  required id="last_name" maxLength="80" name="last_name" size="20" type="text" />
                        </div>
                        <div className='row'>
                            <label htmlFor="email">Correo electrónico</label>
                            <input id="email" maxLength="80" name="email" size="20" type="text" />
                        </div>
                        <div className='row'>
                            <label htmlFor="mobile">Móvil</label>
                            <input required id="mobile" maxLength="40" name="mobile" size="20" type="text" />
                        </div>
                        {/* <label htmlFor="lead_source">Origen del candidato</label> */}
                        <select className='ocultar'  id="lead_source" name="lead_source" defaultValue={'Internet (Web to Lead)'}>
                            {/* <option defaultValue="Campaña Web">Campaña Web</option> */}
                            <option value="Internet (Web to Lead)">Internet (Web to Lead)</option>
                        </select>
                        {/* <label htmlFor="recordType">Tipo de registro de candidato</label> */}
                        <select className='ocultar' id="recordType" name="recordType" defaultValue={'0121P000000VoUh'}>
                            {/* <option defaultValue="0121a000000VOP9">Corporativo</option> */}
                            {/* <option selected defaultValue="0121P000000VoUh">Davivir MX</option> */}
                            <option value="0121P000000VoUh">Davivir MX</option>
                        </select>
                        {
                           varHome===true &&
                            <div className='row'>
                                <label>Proyecto</label>
                                <select onChange={(e)=>handleSelect(e)}>
                                    <option value="a011P00000KRF1GQAX">MONTECARLO</option>
                                    <option value="a011P00000KRF1VQAX">BOSQUES DE IBIZA</option>
                                    <option value="a011P00000KRF1LQAX">PUNTA PALERMO</option>
                                </select>
                            </div>
                        }
                        {/* {
                           !varHome && */}
                            <input className='ocultar' value={idProyecto} id="00N1a000009GF2V" maxLength="18" name="00N1a000009GF2V" size="20" type="text" readOnly/>
                        {/* } */}
                       
                        <select className='ocultar' id="00N1P00000A9Z1S" name="00N1P00000A9Z1S" title="Medios" defaultValue={'PAGINA WEB DAVIVIR'}>
                            {/* <option defaultValue="TV">TV</option> */}
                            <option value="PAGINA WEB DAVIVIR">PAGINA WEB DAVIVIR</option>
                        </select>
                        {/* <input onClick={()=>{submit()}} type="button" name="submit" value={"Enviar"}/> */}
                        
                        <div className='row'>
                        <p>*Al enviar acepta nuestros <span onClick={()=>{showModalAviso()}}>términos de privacidad</span></p>
                            <input type="submit" name="submit" value={"Enviar"}/>
                        </div>
                    </form>
                </div>
            </div>
        
        </>
     );
}
 
export default SalesForce;