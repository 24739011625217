import React from "react";
import "./Footer.scss";
const Footer = (props) => {
  const copyright = new Date();

  const update = copyright.getFullYear();
  return (
    <div className="container__footer">
      <img src="/assets/imagenes/home/logo-davivir.png" alt="logo" />
      <div>
        <p>CORPORATIVO CDMX</p>
        <p>
          Esquina Avenida Ozumbilla y Paseo Castilla, Ojo de Agua, Tecámac,
          Estado de México, C.P. 55767
        </p>
      </div>
      <p
        onClick={() => {
          props.showModal(true);
        }}
      >
        Aviso de privacidad
      </p>
      <p>COPYRIGHT &copy; {update} | DERECHOS RESERVADOS | DAVIVIR</p>
      <div>
        <p>CORPORATIVO AGUASCALIENTES</p>
        <p>
          Av Prolongación Zaragoza 1301, Interior 203, Fracc Pulgas Pandas
          Norte, Aguascalientes, C.P. 20138
        </p>
      </div>
    </div>
  );
};

export default Footer;
