import React,{ useEffect, useState } from 'react';
import {Helmet} from "react-helmet";
//import Tarjeta from "../../componentes/Tarjeta/Tarjeta"
import Tarjeta from "../../componentes/Tarjeta/Tarjetav2"
import '../../componentes/Tarjeta/Tarjeta.scss';
import Paginacion from "../../componentes/Paginacion/Paginacion"
import axios from 'axios'
import Whatsapp from '../../componentes/Whatsapp/Whatsapp';

const Blog = (props) => {
    const [articulos, setarticulos] = useState([]);
    const [paginas, setPaginas] = useState(0);
    useEffect(()=>{
        axios({
            method: 'get',
            //url: 'https://panelcontrol.davivir.com.mx/proyectoslist',
             url: 'https://panelcontrol.davivir.com.mx/blog/list',
        }).then(function (response) {
            setarticulos(response.data.data);
            setPaginas(response.data.last_page)
            //console.log(response.data,'respuesta')
            // moment.locale('es');
            // setFecha(moment(response.data[0].created_at).format('ll')) 
        });
        setTimeout(() => {
            props.setOculatr(false)
        }, 1000)
        return () => {
            props.setOculatr(true)
            // document.getElementById('hellobox-widget').remove()
            // document.getElementById('hellobox-bottom-bar').remove()
            // console.log("salimos home")
        }
    },[])

    //console.log('data',articulos)
    // console.log('paginas',paginas)
    return ( 
        <>
        <Whatsapp tel={'https://api.whatsapp.com/send/?phone=5215541375090&text&type=phone_number&app_absent=0'}></Whatsapp>
        {/* <Helmet>
            <script  id="hbx_widget"  data-cfasync="false" type="text/javascript" src="https://hellobox.chat/dist/widget.min.js" data-token="34586d4b3844495756363957623256697a59564a51413d3d"  async="async"></script>
        </Helmet> */}
        <div className='contenedor_blog'>
            <div className='contenedor_blog_flex'>
                {
                    articulos.map((articulo)=>{
                        return(
                            <Tarjeta
                                titulo={articulo.articulo_titulo}
                                detalle={articulo.articulo_detalle}
                                portada={articulo.articulo_portada}
                                fecha={articulo.created_at}
                                imgFull={articulo.articulo_imgFull}
                                contenido={articulo.articulo_contenido}
                                slug={articulo.articulo_slug}
                            />
                        )
                    })
                }
            </div>
            <Paginacion 
            setarticulos ={setarticulos}
            paginas ={paginas}
            />
        </div>
        </>
     );
}
 
export default Blog;