import React from 'react';
import './Popup.scss'

const Popup = (props) => {
    const banner = () =>{
        if(props.bannerTipo ==="promo"){
            props.showModal(true)
            props.setTipoModal("¡GRACIAS POR TU INTERÉS!")
            props.setSubtitulo("Permítenos mostrarte los nuevos modélos de casa que tenemos para tí");
            props.setpopup(false)
        }
    }
    return ( 
        <div className="contenedor__popup">
            <div className="modal">
                <p onClick={()=> props.setpopup(false)}>X</p>
                <img onClick={()=> banner()}src={props.urlImgPopup} alt="popup" />
            </div>
        </div>
     );
}
 
export default Popup;