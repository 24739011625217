import * as React from 'react';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import axios from 'axios'
export default function PaginationButtons(props) {
    const [page, setPage] = React.useState(1);
    const handleChange = (event, value) => {
      setPage(value);
      //props.setCurrentPage(value)
      axios({
        method: 'get',
        //url: 'https://panelcontrol.davivir.com.mx/proyectoslist',
         url: `http://localhost:8000/blog/list?page=${value}`,
    }).then(function (response) {
        props.setarticulos(response.data.data);
        //console.log(response.data,'respuesta')
        // moment.locale('es');
        // setFecha(moment(response.data[0].created_at).format('ll')) 
    });
    };
    // console.log('pageee: ', page)
  return (
    <Stack spacing={2}>
      <Pagination count={props.paginas} onChange={handleChange} />
    </Stack>
  );
}