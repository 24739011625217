import React, { useState } from "react";
import "./Principal.scss";
import Slider from "../Slider/Slider";
import { SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";

const Principal = (props) => {
  const [mainControlledSwiper, setMainControlledSwiper] = useState(null);
  const [controlledSwiper, setControlledSwiper] = useState(null);

  const modalTemp = () => {
    props.setBannerTipo("temp");
    props.setpopup(true);
    props.setUrlImgPopup("./assets/imagenes/Popup2.jpg");
  };
  return (
    <div className="container__principal">
      <img
        className="cuadros"
        src="./assets/imagenes/rectangulos.png"
        alt="rectangulos"
      />
      <div className="container__principal_slogan">
        <img
          className="slogan wow animate__animated animate__fadeInRight animate__delay-1s"
          src="./assets/imagenes/home/slogan.png"
          alt="slogan"
        />
      </div>
      <img
        className="bottom__triangulo1"
        src="./assets/imagenes/home/forma-1.png"
        alt="triangulo"
      />
      <img
        className="degradado"
        src="./assets/imagenes/home/degradado2.png"
        alt="degradado"
      />
      <div className="container__principal_carousel">
        <Slider
          nav={false}
          controller={{ control: controlledSwiper }}
          onSwiper={setMainControlledSwiper}
        >
          <SwiperSlide>
            <div className="container__principal_carousel__slider">
              <img
                src="./assets/imagenes/home/slider/chico-home1.png"
                alt="mujer"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="container__principal_carousel__slider">
              <img
                src="./assets/imagenes/home/slider/chica-home2.png"
                alt="mujer"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="container__principal_carousel__slider">
              <img
                src="./assets/imagenes/home/slider/chica-home3.png"
                alt="mujer"
              />
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="container__principal_carousel__slider">
              <img
                src="./assets/imagenes/home/slider/chica-home4.png"
                alt="mujer"
              />
            </div>
          </SwiperSlide>
        </Slider>
        <div className="raya">
          <img src="./assets/imagenes/home/Elipse.png" alt="elipse" />
        </div>
      </div>
      <div className="container__principal_frase">
        <div className="container__principal_frase_top">
          <Slider
            nav={false}
            controller={{ control: mainControlledSwiper }}
            enableAutoplay={false}
            onSwiper={setControlledSwiper}
          >
            <SwiperSlide>
              <img
                className="frase  animate__animated animate__fadeIn animate__delay-2s"
                src="./assets/imagenes/home/slider/texto-home1.png"
                alt="frase"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="frase  animate__animated animate__fadeIn animate__delay-2s"
                src="./assets/imagenes/home/slider/texto-home2.png"
                alt="frase"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="frase  animate__animated animate__fadeIn animate__delay-2s"
                src="./assets/imagenes/home/slider/texto-home3.png"
                alt="frase"
              />
            </SwiperSlide>
            <SwiperSlide>
              <img
                className="frase  animate__animated animate__fadeIn animate__delay-2s"
                src="./assets/imagenes/home/slider/texto-home4.png"
                alt="frase"
              />
            </SwiperSlide>
          </Slider>
        </div>
        <div className="propiedades  animate__animated animate__fadeInRight animate__delay-1s">
          <Link to="/san-patricio">
            <img
              src="./assets/imagenes/home/logos-proyectos/sp2.png"
              alt="san patricio"
            />
          </Link>
          <Link to="/palermo">
            <img
              src="./assets/imagenes/home/logos-proyectos/pp.png"
              alt="punta palermo"
            />
          </Link>
          <Link to="/ibiza">
            <img
              src="./assets/imagenes/home/logos-proyectos/ibiza.png"
              alt="ibiza"
            />
          </Link>
          <Link to="/montecarlo">
            <img
              src="./assets/imagenes/home/logos-proyectos/montecarlo.png"
              alt="montecarlo"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Principal;
