import React from "react";
import "./Portada.scss";
import { ProjectCover } from "../ProjectCover/ProjectCover";
const Portada = (props) => {
  return (
    <div className="contenedor__port">
      <div className="contenedor__port_top contenedor__port_top--project">
        <ProjectCover
          phraseImg={props.urlSlogan}
          projectImg={props.urlPrototipo}
        />
        <img
          className="bottom__triangulo1"
          src="./assets/imagenes/home/forma-1.png"
          alt="triangulo"
        />
        <img
          className="degradado"
          src="./assets/imagenes/home/degradado2.png"
          alt="degradado"
        />
      </div>
      <div className="contenedor__port_equipo">
        <div className="equipamiento">
          <img src={props.urlEquipamiento} alt="Nuestor equipamiento" />
        </div>
        <div className="amenidades">
          <div>
            <img src={props.urlAmenidad1} alt="amenidades" />
          </div>
          <div>
            <div>
              <img src={props.urlAmenidad2} alt="amenidades" />
            </div>
            {props.urlAmenidad4 && (
              <div>
                <img src={props.urlAmenidad4} alt="amenidades" />
              </div>
            )}
            <div>
              <img
                className="amenidades3"
                src={props.urlAmenidad3}
                alt="amenidades"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Portada;
