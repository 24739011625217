import React, { useEffect, useState } from "react";
import Portada from "../../componentes/Portada/Portada";
import { CSSTransition } from "react-transition-group";
import Prototipos from "../../componentes/Prototipos/Prototipos";
import DetallePrototipo from "../../componentes/DetallePrototipo/DetallePrototipo";
import { Ubicacion } from "../../componentes/Ubicacion/Ubicacion";
import Whatsapp from "../../componentes/Whatsapp/Whatsapp";
import axios from "axios";
import { Helmet } from "react-helmet";
import { SwiperSlide } from "swiper/react";
import ScrollAnimation from "react-animate-on-scroll";
const destinations = {
  ORIGEN: {
    lat: 21.884271687642595,
    lng: -102.24290613960254,
  },
  CENTRO: {
    lat: 21.882850769380614,
    lng: -102.29557432409318,
  },
  IMSS: {
    lat: 21.877033782425674,
    lng: -102.2545451372787,
  },
  ALTARIA: {
    lat: 21.92301882207393,
    lng: -102.28974849103177,
  },
  AURRERA: {
    lat: 21.877329652329816,
    lng: -102.24031893722194,
  },
  ESTADIO: {
    lat: 21.880909316375813,
    lng: -102.2757408699222,
  },
};

const Patricio = (props) => {
  const [prototipos, getPrototipos] = useState([]);
  const [galerias, getGalerias] = useState([]);
  const [showDetalle, setDetalle] = useState(false);
  const [urlfachada, setUrlFachada] = useState("");
  const [urlPlanta, setUrlPlanta] = useState("");
  const [desc, setDesc] = useState("");
  const [group, setNumber] = useState(5);
  const [brochure, Brochure] = useState("");
  let [selectdestination, setDestination] = useState(null);
  const [urlRecorido, setRecorrido] = useState("");
  useEffect(() => {
    props.setIbiza(true);
    axios({
      method: "get",
      url: "https://panelcontrol.davivir.com.mx/prototiposlist/4",
    }).then(function (response) {
      getPrototipos(response.data);
      Brochure(
        `https://panelcontrol.davivir.com.mx${response.data[0].prototipo_urlBrochure}`
      );
      setRecorrido(`${response.data[0].prototipo_urlRecorrido}`);
      if (window.screen.width < 550) {
        setNumber(1);
      } else {
        if (response.data.length >= 3) {
          setNumber(3);
        } else {
          setNumber(response.data.length);
        }
      }
      const activos = response.data.filter(
        (prototipo) => prototipo.prototipo_estado !== "agotado"
      );
      axios({
        method: "get",
        url: `https://panelcontrol.davivir.com.mx/galeriaslist/${activos[0].prototipo_id}`,
      }).then(function (response) {
        getGalerias(response.data);
        setUrlFachada(
          `https://panelcontrol.davivir.com.mx${activos[0].prototipo_urlFachada}`
        );
        setUrlPlanta(
          `https://panelcontrol.davivir.com.mx${activos[0].prototipo_urlPlanta}`
        );
        setDesc(activos[0].prototipo_descripcion);
        setDetalle(true);
        setTimeout(() => {
          props.setOculatr(false);
        }, 1000);
      });
    });
    return () => {
      props.setOculatr(true);
      // const helloboxWidghet = document.getElementById('hellobox-widget')
      // const helloboxBottomBar = document.getElementById('hellobox-bottom-bar')

      // if (helloboxWidghet && helloboxBottomBar) {
      //     helloboxBottomBar.remove();
      //     helloboxWidghet.remove();
      // }
    };
  }, []);

  useEffect(() => {
    function handleResize(p) {
      if (window.innerWidth < 550) {
        setNumber(1);
      } else {
        if (prototipos.length >= 3) {
          setNumber(3);
        } else if (prototipos.length > 0) {
          setNumber(prototipos.length);
        }
      }
    }
    window.addEventListener("resize", handleResize(prototipos.length));
  });
  const getGaleria = (prototipo) => {
    if (prototipo.prototipo_estado !== "agotado") {
      axios({
        method: "get",
        url: `https://panelcontrol.davivir.com.mx/galeriaslist/${prototipo.prototipo_id}`,
      }).then(function (response) {
        getGalerias(response.data);
        setUrlFachada(
          `https://panelcontrol.davivir.com.mx${prototipo.prototipo_urlFachada}`
        );
        setUrlPlanta(
          `https://panelcontrol.davivir.com.mx${prototipo.prototipo_urlPlanta}`
        );
        setDesc(prototipo.prototipo_descripcion);
        Brochure(
          `https://panelcontrol.davivir.com.mx${prototipo.prototipo_urlBrochure}`
        );
        setDetalle(true);
        setRecorrido(`${prototipo.prototipo_urlRecorrido}`);
        //console.log(response,'respuesta')
      });
    } else {
      // getGalerias([]);
      // setUrlFachada('')
      // setUrlPlanta('')
      // setDesc('')
      // setDetalle(false)
    }
  };
  return (
    <div>
      {/* <Helmet>
                <script  id="hbx_widget"  data-cfasync="false" type="text/javascript" src="https://hellobox.chat/dist/widget.min.js" data-token="414b4d6a7345585742434a5054755a71374d753750513d3d"  async="async"></script>
            </Helmet> */}
      <Whatsapp
        tel={
          "https://api.whatsapp.com/send/?phone=5214499766312&text&type=phone_number&app_absent=0"
        }
      ></Whatsapp>
      <Portada
        urlSlogan="./assets/imagenes/San patricio/slogan-sanpatricio.png"
        urlPrototipo="./assets/imagenes/San patricio/modelo-san-patricio.png"
        urlEquipamiento="./assets/imagenes/San patricio/equi-patricio.png"
        urlAmenidad1="./assets/imagenes/San patricio/linea-verde.png"
        urlAmenidad2="./assets/imagenes/San patricio/centro.png"
        urlAmenidad3="./assets/imagenes/San patricio/a-tu-alcance.png"
      />
      <Prototipos group={group}>
        {prototipos.map((val, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="card" key={index} onClick={() => getGaleria(val)}>
                <img
                  key={index}
                  src={`https://panelcontrol.davivir.com.mx${val.prototipo_urlPortada}`}
                  alt="prototipo"
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Prototipos>
      <CSSTransition
        in={showDetalle}
        timeout={200}
        classNames="deslizar"
        unmountOnExit
      >
        <DetallePrototipo
          correo={"patricio"}
          setCorreo={props.setCorreo}
          showModal={props.showModal}
          setTipoModal={props.setTipoModal}
          setSubtitulo={props.setSubtitulo}
          showCarusel={props.showCarusel}
          setGaleria={props.setGaleria}
          galerias={galerias}
          urlfachada={urlfachada}
          urlPlanta={urlPlanta}
          urlBrochure={props.setBrochure}
          brochure={brochure}
          desc={desc}
          showRecorrido={props.showRecorrido}
          setUrlRecorrido={props.setUrlRecorrido}
          urlRecorido={urlRecorido}
        />
      </CSSTransition>
      <Ubicacion
        mainFraccImg="./assets/imagenes/San patricio/Location/entrada.png"
        locationImageRoute="./assets/imagenes/San patricio/Location/mapa.png"
        marker="./assets/imagenes/Ibiza/location/marker.png"
        logo="./assets/imagenes/San patricio/Location/logo-san-patricio.png"
        mapIcon="./assets/imagenes/Ibiza/location/icono-mapa-ios.png"
        locationTextImg={
          <img
            className="locationTextImg"
            style={{ height: "150px" }}
            src="./assets/imagenes/San patricio/Location/vamos-flecha.png"
          />
        }
        peopleImg={
          <img
            className="people"
            style={{ height: "90%", right: "0px" }}
            src="./assets/imagenes/San patricio/Location/personas.png"
          />
        }
        mapLinks={{
          googleMaps: "https://maps.app.goo.gl/cUFMZUPQDfhMqshF9",
          waze: "https://www.waze.com/en/live-map/directions/mx/ags./aguascalientes/colinas-de-san-patricio-oficina-de-ventas?utm_campaign=waze_website&utm_medium=website_menu&utm_source=waze_website&to=place.ChIJZa_icPDxKYQRJe7BvQzYAi8",
        }}
        address={
          <>
            {" "}
            <span>Av. Poliducto s/n Av. San Francisco</span>{" "}
            <span>de los Viveros, Col. Colinas de San Patricio.</span>{" "}
            <span>C.P. 20196</span>
          </>
        }
      />
    </div>
  );
};

export default Patricio;
