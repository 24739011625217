import React, {useEffect} from 'react';
import './Gracias.scss'
const Gracias = (props) => {

    useEffect(()=>{
        setTimeout(() => {
            props.setOculatr(false)
        }, 1000)
    },[])
    return ( 
        <div className='gracias'>
            <h1>¡GRACIAS POR COMUNICARTE, NUESTRO EQUIPO SE PONDRÁ EN CONTACTO CONTIGO!</h1>
        </div>
     );
}
 
export default Gracias;