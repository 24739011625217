import React from 'react';
// Import Swiper React components
import { Swiper } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import "swiper/components/navigation/navigation.min.css"
import SwiperCore, { 
    Autoplay,
    Navigation,
    Pagination
  } from 'swiper/core';
  
  // install Swiper modules
SwiperCore.use([Pagination,Autoplay, Navigation ]);
const SliderGroup = (props) => {
    const {children, group} = props;
    return ( 
        <>
            <Swiper slidesPerView={group}
                spaceBetween={30}
                slidesPerGroup={group}
                loop={false}
                loopFillGroupWithBlank={true}
                pagination={{
                clickable: true,
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper" >
                    {children}
            </Swiper>
        </>
     );
}
 
export default SliderGroup;