import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { red } from '@mui/material/colors';
import PreviewIcon from '@mui/icons-material/Preview';
import moment from 'moment'
import 'moment/locale/es';
import { Link } from "react-router-dom";
import Facebook from '../../componentes/Contactanos/Facebook/Facebook';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard(props) {
  const [expanded, setExpanded] = React.useState(false);
  const [fecha, setFecha] = React.useState('');

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  React.useEffect(() => {
    setFecha(moment(props.fecha).format('ll'));
    moment.locale('es');
  }, [])

  //fecha.locale(false);
  //alert(localLocale.format('LLLL')); 
  //console.log(fecha)
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
            D
          </Avatar>
        }
        title={props.titulo}
        subheader={fecha}
      />
      <CardMedia
        component="img"
        height="194"
        image={`https://panelcontrol.davivir.com.mx/${props.portada}`}
        alt="Paella dish"
      />
      <CardContent>
        <Typography variant="body2" color="text.secondary">
          {props.detalle}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <IconButton aria-label="share">
          <Facebook url={`https://davivir.com.mx/blog/${props.slug}`} />
        </IconButton>
        <IconButton aria-label="add to favorites">
          <Link to={`/blog/${props.slug}`}><PreviewIcon /></Link>
        </IconButton>
      </CardActions>
    </Card>
  );
}