import React from 'react';
import ShareIcon from '@mui/icons-material/Share';
import {
    FacebookShareButton
  } from "react-share";
const Facebook = (props) => {
    return ( 
        <FacebookShareButton url={props.url}>
            <ShareIcon/>
        </FacebookShareButton>
     );
}
 
export default Facebook;