import "./ProjectCover.scss";

export const ProjectCover = ({ phraseImg, projectImg }) => {
  return (
    <div className="main-cover">
      <div className="main-cover__phrase-img">
        <img src={phraseImg} />
      </div>
      <div className="main-cover__project-img">
        <img src={projectImg} />
      </div>
    </div>
  );
};
