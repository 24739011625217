import React,{useState,useEffect} from 'react';
import { useParams } from 'react-router';
import axios from 'axios'
import './DetalleArticulo.scss'
import { Link } from "react-router-dom";
import moment from 'moment'
import 'moment/locale/es';
import Facebook from '../../componentes/Contactanos/Facebook/Facebook';
const DetalleArticulo = (props) => {
    const [articulo, setArticulo] = useState(null)
    const { slug } = useParams();
    const [fecha, setFecha] = useState('');
    useEffect(() => {
        axios({
            method: 'get',
            //url: 'https://panelcontrol.davivir.com.mx/proyectoslist',
             url: `https://panelcontrol.davivir.com.mx/blog/${slug}`,
        }).then(function (response) {
            setArticulo(response.data);
            setFecha(moment(response.data.created_at).format('ll'));
            moment.locale('es');
            //console.log(response.data,'respuesta')
            // moment.locale('es');
            // setFecha(moment(response.data[0].created_at).format('ll')) 
            setTimeout(() => {
                props.setOculatr(false)
            }, 1000)
        });
    },[])
    // console.log(articulo)
    return ( 
        <>
        { articulo && 
            <div className='contenedor_detalleArticulo'>
                <div className="contenedor_detalleArticulo_detalle">
                    <div className='img_portada'>
                        <img src={`https://panelcontrol.davivir.com.mx${articulo.articulo_imgFull}`} alt="" />
                    </div>
                    <div className='articulo_titulo'>
                        <div className='fecha'> 
                            <p>{fecha}</p>
                            <Facebook url={`https://davivir.com.mx/blog/${articulo.articulo_slug}`}/>
                        </div>
                        <h1>{articulo.articulo_titulo}</h1>
                    </div>
                    <div className='articulo_contenido' dangerouslySetInnerHTML={{ __html: articulo.articulo_contenido }}></div>
                    
                </div>
                <Link to="/blog">Regresar</Link>
            </div>
        }
        </>

     );
}
 
export default DetalleArticulo;