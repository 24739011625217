import React, { useEffect, useState } from "react";
import Portada from "../../componentes/Portada/Portada";
import { CSSTransition } from "react-transition-group";
import Prototipos from "../../componentes/Prototipos/Prototipos";
import DetallePrototipo from "../../componentes/DetallePrototipo/DetallePrototipo";
import { Ubicacion } from "../../componentes/Ubicacion/Ubicacion";
import axios from "axios";
import Whatsapp from "../../componentes/Whatsapp/Whatsapp";
import { Helmet } from "react-helmet";
import { SwiperSlide } from "swiper/react";
import ScrollAnimation from "react-animate-on-scroll";
import SalesForce from "../../componentes/SalesForce/SalesForce";
const destinations = {
  ORIGEN: {
    lat: 19.647612882262038,
    lng: -99.02445945654864,
  },
  CDMX: {
    lat: 19.246460952428624,
    lng: -99.1013508686317,
  },
  PACHUCA: {
    lat: 19.927735080082126,
    lng: -98.89169437734144,
  },
  TECAMAC: {
    lat: 19.71118447343089,
    lng: -98.97287164804132,
  },
  PLAZA: {
    lat: 19.708401235446676,
    lng: -98.97677697679562,
  },
  WALMART: {
    lat: 19.65906994758672,
    lng: -99.0184555768544,
  },
};

const Palermo = (props) => {
  const [prototipos, getPrototipos] = useState([]);
  const [galerias, getGalerias] = useState([]);
  const [showDetalle, setDetalle] = useState(false);
  const [urlfachada, setUrlFachada] = useState("");
  const [urlPlanta, setUrlPlanta] = useState("");
  const [desc, setDesc] = useState("");
  const [group, setNumber] = useState(5);
  const [activo, setActivo] = useState("");
  const [brochure, Brochure] = useState("");
  let [selectdestination, setDestination] = useState(null);
  const [urlRecorido, setRecorrido] = useState("");
  useEffect(() => {
    props.setIbiza(true);
    axios({
      method: "get",
      url: "https://panelcontrol.davivir.com.mx/prototiposlist/3",
    }).then(function (response) {
      getPrototipos(response.data);
      Brochure(
        `https://panelcontrol.davivir.com.mx${response.data[0].prototipo_urlBrochure}`
      );
      setRecorrido(`${response.data[0].prototipo_urlRecorrido}`);
      if (window.screen.width < 550) {
        setNumber(1);
      } else {
        if (response.data.length >= 3) {
          setNumber(3);
        } else {
          setNumber(response.data.length);
        }
      }
      const activos = response.data.filter(
        (prototipo) => prototipo.prototipo_estado !== "agotado"
      );
      axios({
        method: "get",
        url: `https://panelcontrol.davivir.com.mx/galeriaslist/${activos[0].prototipo_id}`,
      }).then(function (response) {
        getGalerias(response.data);
        setUrlFachada(
          `https://panelcontrol.davivir.com.mx${activos[0].prototipo_urlFachada}`
        );
        setUrlPlanta(
          `https://panelcontrol.davivir.com.mx${activos[0].prototipo_urlPlanta}`
        );
        setDesc(activos[0].prototipo_descripcion);
        setDetalle(true);
        setTimeout(() => {
          props.setOculatr(false);
        }, 1000);
      });
    });
    return () => {
      props.setOculatr(true);
      // const helloboxWidghet = document.getElementById('hellobox-widget')
      // const helloboxBottomBar = document.getElementById('hellobox-bottom-bar')

      // if (helloboxWidghet && helloboxBottomBar) {
      //     helloboxBottomBar.remove();
      //     helloboxWidghet.remove();
      // }
    };
  }, []);

  useEffect(() => {
    function handleResize(p) {
      if (window.innerWidth < 550) {
        setNumber(1);
      } else {
        if (prototipos.length >= 3) {
          setNumber(3);
        } else if (prototipos.length > 0) {
          setNumber(prototipos.length);
        }
      }
    }
    window.addEventListener("resize", handleResize(prototipos.length));
  });
  const getGaleria = (prototipo) => {
    if (prototipo.prototipo_estado !== "agotado") {
      axios({
        method: "get",
        url: `https://panelcontrol.davivir.com.mx/galeriaslist/${prototipo.prototipo_id}`,
      }).then(function (response) {
        getGalerias(response.data);
        setUrlFachada(
          `https://panelcontrol.davivir.com.mx${prototipo.prototipo_urlFachada}`
        );
        setUrlPlanta(
          `https://panelcontrol.davivir.com.mx${prototipo.prototipo_urlPlanta}`
        );
        setDesc(prototipo.prototipo_descripcion);
        Brochure(
          `https://panelcontrol.davivir.com.mx${prototipo.prototipo_urlBrochure}`
        );
        setDetalle(true);
        setRecorrido(`${prototipo.prototipo_urlRecorrido}`);
        //console.log(response,'respuesta')
      });
    } else {
      // getGalerias([]);
      // setUrlFachada('')
      // setUrlPlanta('')
      // setDesc('')
      // setDetalle(false)
    }
  };
  return (
    <div>
      {/* <Helmet>
                <script  id="hbx_widget"  data-cfasync="false" type="text/javascript" src="https://hellobox.chat/dist/widget.min.js" data-token="3841717250482f3035373035562f3157385973546e513d3d"  async="async"></script>
            </Helmet> */}
      <Whatsapp
        tel={
          "https://api.whatsapp.com/send/?phone=5215513676249&text&type=phone_number&app_absent=0"
        }
      ></Whatsapp>
      <Portada
        urlSlogan="./assets/imagenes/Palermo/slogan-palermo.png"
        urlPrototipo="./assets/imagenes/Palermo/modelo-palermo.png"
        urlEquipamiento="./assets/imagenes/Palermo/equi-palermo.png"
        urlAmenidad1="./assets/imagenes/Montecarlo/aeropuerto.png"
        urlAmenidad2="./assets/imagenes/Palermo/linea-1.png"
        urlAmenidad3="./assets/imagenes/Palermo/autopista.png"
        urlAmenidad4="./assets/imagenes/Palermo/linea4.png"
      />
      <Prototipos group={group}>
        {prototipos.map((val, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="card" key={index} onClick={() => getGaleria(val)}>
                <img
                  key={index}
                  src={`https://panelcontrol.davivir.com.mx${val.prototipo_urlPortada}`}
                  alt="prototipo"
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Prototipos>
      <CSSTransition
        in={showDetalle}
        timeout={200}
        classNames="deslizar"
        unmountOnExit
      >
        <DetallePrototipo
          correo={"palermo"}
          setCorreo={props.setCorreo}
          showModal={props.showModal}
          setTipoModal={props.setTipoModal}
          setSubtitulo={props.setSubtitulo}
          showCarusel={props.showCarusel}
          setGaleria={props.setGaleria}
          galerias={galerias}
          urlfachada={urlfachada}
          urlPlanta={urlPlanta}
          urlBrochure={props.setBrochure}
          brochure={brochure}
          desc={desc}
          showRecorrido={props.showRecorrido}
          setUrlRecorrido={props.setUrlRecorrido}
          urlRecorido={urlRecorido}
          setShowSalesForce={props.setShowSalesForce}
        />
      </CSSTransition>
      <SalesForce
        showSegundoM={props.showSegundoM}
        showModal2={props.showModal2}
        nomodal={"nomodal"}
        titulo={"CONTÁCTANOS"}
      ></SalesForce>
      <Ubicacion
        mainFraccImg="./assets/imagenes/Palermo/Location/entrada.png"
        locationImageRoute="./assets/imagenes/Palermo/Location/mapa-palermo.png"
        logo="./assets/imagenes/Palermo/Location/logo-p-palermo.png"
        mapIcon="./assets/imagenes/Ibiza/location/icono-mapa-ios.png"
        locationTextImg="./assets/imagenes/Palermo/Location/se-llega-a.png"
        peopleImg={
          <img
            className="people"
            style={{ height: "80%", right: "-10px" }}
            src="./assets/imagenes/Palermo/Location/personas.png"
          />
        }
        mapLinks={{
          googleMaps: "https://maps.app.goo.gl/7N9JNxFByQYYH4p1A",
          waze: "https://ul.waze.com/ul?place=ChIJU7yLRRDy0YURuZgFx6pRauU&ll=19.64228450%2C-99.02422710&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location",
        }}
        address={
          <>
            {" "}
            <span>Av Ozumbilla, Esq Av Paseo Castilla, SN</span>{" "}
            <span>Col. Punta Palermo, C.P: 55767</span>{" "}
            <span>Tecámac, Estado de México</span>
          </>
        }
      />
    </div>
  );
};

export default Palermo;
