import React from 'react';
import './Aviso.scss'

const Aviso = (props) => {
    return ( 
            <div className="contenido">
                <h1>AVISO DE PRIVACIDAD</h1>
                <div className="texto-aviso">
                    <h3>I.Responsable de la protección de sus datos personales.</h3>
                    <p>
                    De conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, 
                    Desarrollos INVI S.A. DE C.V. Y DAVIVIR Desarrollos Inmobiliarios S.A. DE C.V., (en adelante el responsable), 
                    con domicilio en Av. Ejército Nacional No. 1136 Int. 201, Colonia Los Morales, Delegación Miguel Hidalgo, México, D.F., 
                    C.P.11510 es el responsable del tratamiento que se le dé a sus datos personales de conformidad con el presente Aviso de Privacidad.
                    </p>
                    <h3>II. Datos personales que serán sometidos a tratamiento.</h3>
                    <p>Cuando usted está interesado en adquirir un bien inmueble que el responsable le ha ofrecido puede proporcionar diversos datos personales.
                    Los datos personales que serán sometidos a tratamiento por el responsable y que se recaban de forma personal, directa o indirecta son todos o alguno de los datos personales que se mencionan a continuación:
                    Datos personales de identificación: nombre, apellidos, registro federal de contribuyentes, domicilio, y número de identificación de credencial de elector.
                    Datos personales de comunicación: correo electrónico y número de teléfono particular y celular.
                    </p>
                    <h3>III. Consentimiento.</h3>
                    <p>De conformidad con los artículos 8 y 10, fracción IV de la Ley, no se requiere su consentimiento para el tratamiento de sus datos
                    personales, toda vez que éstos son indispensables para el cumplimiento de una relación jurídica entre usted y el responsable.</p>
                    <h3>IV. Finalidad del tratamiento</h3>
                    <p>Las finalidades que dan origen y SÍ son necesarias para la existencia, mantenimiento y cumplimiento de la relación jurídica entre el responsable y usted son las siguientes:
                        1. Tener contacto o comunicación con usted.
                        2. Mostrarle los bienes inmuebles que el responsable vende cuando ha 	manifestado su interés en comprar alguno de ellos.
                    El responsable no da tratamiento a sus datos personales para finalidades que no sean necesarias para la existencia, mantenimiento y cumplimiento de la relación jurídica.
                    </p>
                    <h3>V. Transferencia de datos personales, tercero receptor y finalidad de la transferencia.</h3>
                    <p>
                    El tratamiento de sus datos personales involucra transferencias nacionales.
                    Las transferencias que se mencionan a continuación NO requieren su consentimiento, de conformidad con el artículo 37, fracciones III y VII de la Ley, son las siguientes:

                        a) Sociedades del mismo grupo del responsable, la finalidad de esta transferencia es para darle la asesoría necesaria para la compra de los bienes inmuebles.
                        b) Sociedades de información crediticia, la finalidad de esta transferencia es para conocer su historial de crédito y poder apoyarle en el proceso de compra del inmueble, y sólo se realiza cuando usted ha manifestado su aceptación para la consulta de dicho historial.
                    El responsable no realiza transferencias respecto de las cuales se requiera su consentimiento.
                    </p>
                    <h3>VI. Medios y procedimiento para ejercer derechos de Acceso, Rectificación, Cancelación y Oposición (ARCO).</h3>
                    <p>Usted tiene derecho de ejercer cualquiera de los derechos de acceso, rectificación, cancelación u oposición que se prevén en la Ley a través del formato implementado por el responsable, lo cual puede realizar por los siguientes medios:
                    a) Presentar su solicitud por correo electrónico a la dirección electrónica 	arco@davivir.com.mx dirigida al Departamento de Datos Personales.
                    b) Presentar su solicitud directamente en el domicilio del responsable.
                    Procedimiento para ejercer derechos ARCO y obtención del formato de solicitud de derechos.
                    Si desea conocer los procedimientos y/u obtener el formato para ejercer sus derechos ARCO puede enviar un correo electrónico a arco@davivir.com.mx, puede ponerse en contacto con nuestro Departamento de Datos Personales al número telefónico 01 (449) 996 1111, o bien acudir al domicilio del responsable señalado al inicio de este Aviso de Privacidad.
                    </p>
                    <h3>VII. Mecanismo y procedimiento para revocar el consentimiento al tratamiento de datos personales.</h3>
                    <p>
                    De conformidad con el artículo 8 de la Ley, se entiende que usted consiente tácitamente el tratamiento de sus datos personales cuando se ponen a su disposición el presente Aviso de Privacidad, y usted no manifiesta su oposición.
                    Usted podrá revocar su consentimiento en cualquier momento sin que se le atribuyan efectos retroactivos, lo cual puede realizar a través de los medios y procedimientos implementados por el responsable.
                    Si desea conocer dichos medios y procedimientos, puede ponerse en contacto con nuestro Departamento de Datos Personales en los “Datos de Contacto” señalados al final del presente Aviso de Privacidad.
                    </p>
                    <h3>VIII. Opciones y medios para limitar el uso o divulgación de los datos personales.</h3>
                    <p>
                    Si usted desea limitar el uso o divulgación de dichos datos podrá solicitarlo por correo electrónico a nuestro Departamento de Datos Personales a la dirección electrónica arco@davivir.com.mx o bien podrá realizarlo por escrito directamente en la dirección del responsable, a través de los procedimientos que el responsable tiene implementados.
                    Si desea conocer los procedimientos para el uso de estas opciones y medios, puede ponerse en contacto con nuestro Departamento de Datos Personales en los “Datos de Contacto” señalados al final del presente Aviso de Privacidad.
                    </p>
                    <h3>IX. Procedimiento y medio por el cual se comunicarán los cambios del presente aviso de privacidad.</h3>
                    <p>Los cambios o actualizaciones al presente Aviso de Privacidad se comunicarán a través de anuncios visibles que se colocarán en el domicilio del responsable o bien a través del correo electrónico. Usted podrá solicitar los cambios o actualizaciones al correo electrónico arco@davivir.com.mx.</p>
                    <h3>Datos de contacto del departamento de datos personales.</h3>
                    <p>
                    En todo momento usted podrá ponerse en contacto con nuestro Departamento de Datos Personales, ya sea para conocer el estado que guarda su solicitud de derechos, resolver dudas y atender quejas, obtener información respecto a la forma de ejercer sus derechos y en general cualquier duda o asesoría relacionada con sus datos personales, lo cual podrá realizar a través del correo electrónico arco@davivir.com.mx, o bien a través del número de teléfono 01 (555) 580 1213, un horario de lunes a viernes de 09:00 a 13:00 hrs.
                    </p>
                </div>
            </div>
     );
}
 
export default Aviso;