import React from "react";
import "./Ubicacion.scss";

export const Ubicacion = ({
  mainFraccImg,
  locationImageRoute,
  peopleImg,
  locationTextImg,
  mapIcon,
  logo,
  address,
  mapLinks,
}) => {
  return (
    <div className="locationBlock">
      <div className="locationBlock__entranceImg">
        <img src={mainFraccImg} />
      </div>

      <div className="locationBlock__markerMap">
        <div className="markerContainer">
          <img
            className="markerIcon"
            src="./assets/imagenes/Ibiza/location/marker.png"
          />
          {typeof peopleImg === "string" ? (
            <img className="people" src={peopleImg} />
          ) : (
            peopleImg
          )}
        </div>
        <div className="mapBlock">
          <img className="mapBlock__mapImg" src={locationImageRoute} />
          <div className="locationText">
            <p>UBICACIÓN</p>
          </div>
          <div className="mapBlock__logoAddress">
            <div className="projectLogo">
              <img src={logo} />
            </div>
            <p>{address}</p>
            <div className="bottomLogos">
              <div className="iconsBlock">
                <p className="visitUsText">Visítanos en:</p>
                <div className="locationImgs">
                  <a target="_blank" href={mapLinks.googleMaps}>
                    {" "}
                    <img src="./assets/imagenes/icono-maps.png" />
                  </a>
                  <a target="_blank" href={mapLinks.waze}>
                    {" "}
                    <img src="./assets/imagenes/icono-waze.png" />
                  </a>
                  <img src={mapIcon} />
                  <div className="phrase">
                    {typeof locationTextImg === "string" ? (
                      <img className="locationTextImg" src={locationTextImg} />
                    ) : (
                      locationTextImg
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
