import React from 'react';
import Slider from '../Slider/Slider';
import { SwiperSlide } from "swiper/react";
import './Carusel.scss'
const Carusel = (props) => {
    //console.log('galeria',props)
    return ( 
        <div className="contenedor__carusel">
            <Slider nav={true}>
                {
                        props.galeria.map((val,index)=>{
                            return(
                                <SwiperSlide key={index}>
                                    <img src={`https://panelcontrol.davivir.com.mx${val.galeria_url}`} alt="galeria" />
                                </SwiperSlide>
                            )
                        })
                }
            </Slider>
        </div>
     );
}
 
export default Carusel;