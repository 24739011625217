import React, { useState } from "react";
import "./Header.scss";
import { Link } from "react-router-dom";

const Header = (props) => {
  const [open, setOpen] = useState("");

  const handleMenu = () => {
    if (window.innerWidth <= 900) {
      setOpen(open === "opened menu-open" ? "" : "opened menu-open");
      // console.log("movile")
    }
  };
  const handleScroll = () => {
    if (window.innerWidth <= 900) {
      setOpen(open === "opened menu-open" ? "" : "opened menu-open");
      // console.log("movile")
    }
    window.scrollTo(0, document.body.scrollHeight);
  };
  const modalTemp = () => {
    props.setBannerTipo("temp");
    props.setpopup(true);
    props.setUrlImgPopup("./assets/imagenes/Popup2.jpg");
  };
  return (
    <div className={`header ${open}`}>
      <p className="btn-toggle" onClick={() => handleMenu()}>
        <span></span>
      </p>
      <nav>
        <Link className="header_logo_movil" to="/">
          <img src="/assets/imagenes/home/logo-davivir.png" alt="logo" />{" "}
        </Link>
        <ul>
          <li>
            <img
              className="cuadros"
              src="/assets/imagenes/rectangulos.png"
              alt="rectangulos"
            />
            <Link to="/quienes-somos" onClick={() => handleMenu()}>
              QUIÉNES SOMOS
            </Link>
          </li>
          <li>
            <img
              className="cuadros"
              src="/assets/imagenes/rectangulos.png"
              alt="rectangulos"
            />
            <Link to="/blog" onClick={() => handleMenu()}>
              BLOG
            </Link>
          </li>
          <li className="proyectos">
            <img
              className="cuadros"
              src="/assets/imagenes/rectangulos.png"
              alt="rectangulos"
            />
            PROYECTOS
            <ul>
              <li>
                <Link to="/ibiza" onClick={() => handleMenu()}>
                  BOSQUES DE IBIZA- Tizayuca
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/montecarlo" onClick={() => handleMenu()}>
                  MONTECARLO - Tecámac
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/palermo" onClick={() => handleMenu()}>
                  PUNTA PALERMO - Tecámac
                </Link>
              </li>
              <li>
                {" "}
                <Link to="/san-patricio" onClick={() => handleMenu()}>
                  COLINAS DE SAN PATRICIO - Aguascalientes
                </Link>
              </li>
            </ul>
          </li>
          <li className="header_logo">
            <Link to="/">
              <img src="/assets/imagenes/home/logo-davivir.png" alt="logo" />{" "}
            </Link>
          </li>
          <li>
            <img
              className="cuadros"
              src="/assets/imagenes/rectangulos.png"
              alt="rectangulos"
            />
            <a onClick={() => handleScroll()}>CONTACTO</a>
          </li>
        </ul>
        <div className="social">
          <div className="social__valle">
            <div className="social__valle_icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/DavivirMex"
              >
                <img src="/assets/imagenes/facebook.png" alt="facebook" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://instagram.com/davivir_mexico?igshid=NmNmNjAwNzg="
              >
                <img src="/assets/imagenes/instagram.png" alt="instagram" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.tiktok.com/@davivir.mx?_t=8WRxbRrhK5e&_r=1"
              >
                <img src="/assets/imagenes/tik-tok.png" alt="tik tok" />
              </a>
            </div>
            <p>Valle de México</p>
          </div>
          <div className="social__aguascalientes">
            <div className="social__aguascalientes_icons">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/DavivirAguascalientesOficial"
              >
                <img src="/assets/imagenes/facebook.png" alt="facebook" />
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/daviviraguascalientes/"
              >
                <img src="/assets/imagenes/instagram.png" alt="instagram" />
              </a>
            </div>
            <p>Aguascalientes</p>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
