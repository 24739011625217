import React,{ useEffect } from 'react';
import './Contactanos.scss'
const Contactanos = (props) => {
    const mostrar = (titulo) => {
        props.setTipoModal(titulo)
        props.setSubtitulo("");
        const pathname = window.location.pathname;
        if(pathname !=='/san-patricio' && titulo==="INFORMES Y VENTAS"){
            props.setShowSalesForce(true)
        }else{
            props.showModal(true);
        }
    } 

    return ( 
        <div className="container__contactanos">
            <img className="wow animate__animated animate__fadeIn" src="/assets/imagenes/home/hola.png" alt="Informes" />
            <a className="wow animate__animated animate__fadeIn" onClick={()=>{mostrar('INFORMES Y VENTAS')}} ><img src="/assets/imagenes/home/informes-y-ventas.png" alt="ventas" /></a>
            <a className="wow animate__animated animate__fadeIn animate__delay-1s" onClick={()=>{mostrar('POSVENTA')}}><img src="/assets/imagenes/home/posventa.png" alt="postventa" /></a>
            <a className="wow animate__animated animate__fadeIn animate__delay-2s" onClick={()=>{mostrar('UNETE AL EQUIPO')}}><img src="/assets/imagenes/home/unete.png" alt="unete al equipo" /></a>
            <div className="linea"></div>
        </div>
     );
}
 
export default Contactanos;