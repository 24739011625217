import React from 'react';
import './Recorrido.scss'
const Recorrido = (props) => {
    return ( 
        <div className='contenedor_recorrido'>
            <iframe title='recorrido' src={props.url}></iframe>
        </div>
     );
}
 
export default Recorrido;