import React from 'react';

const Whatsapp = (props) => {
    return ( 
        <>
            <div className='whatsapp'>
                <a target={'_blank'} href={props.tel}>
                    <img src="/assets/imagenes/whatsapp.png" alt="" />
                </a>
            </div>
        </>
     );
}
 
export default Whatsapp;