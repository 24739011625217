import React,{useState, useEffect} from 'react';
import './Prototipos.scss'
import SliderGroup from '../Slider/SliderGroup';

const Prototipos = (props) => {
    const {children} = props

    return ( 
        <div className="contenedor__prototipos">
            <SliderGroup group={props.group}>
                {children}
            </SliderGroup>
        </div>
     );
}
 
export default Prototipos;