import React, { useEffect, useState } from "react";
import Portada from "../../componentes/Portada/Portada";
import { CSSTransition } from "react-transition-group";
import Prototipos from "../../componentes/Prototipos/Prototipos";
import DetallePrototipo from "../../componentes/DetallePrototipo/DetallePrototipo";
import axios from "axios";
import { Helmet } from "react-helmet";
import Whatsapp from "../../componentes/Whatsapp/Whatsapp";
import { SwiperSlide } from "swiper/react";
import SalesForce from "../../componentes/SalesForce/SalesForce";
import { Ubicacion } from "../../componentes/Ubicacion/Ubicacion";
const destinations = {
  ORIGEN: {
    lat: 19.889160474252414,
    lng: -98.94115106463741,
  },
  CDMX: {
    lat: 19.246460952428624,
    lng: -99.1013508686317,
  },
  PACHUCA: {
    lat: 19.927735080082126,
    lng: -98.89169437734144,
  },
  TECAMAC: {
    lat: 19.71118447343089,
    lng: -98.97287164804132,
  },
  AURRERA: {
    lat: 19.84624062279897,
    lng: -98.9752486938504,
  },
  PLAZA: {
    lat: 19.870992883605876,
    lng: -98.95732423193387,
  },
};
const origen = "19.889160474252414, -98.94115106463741";
const Ibiza = (props) => {
  const [prototipos, getPrototipos] = useState([]);
  const [galerias, getGalerias] = useState([]);
  const [showDetalle, setDetalle] = useState(false);
  const [urlfachada, setUrlFachada] = useState("");
  const [urlPlanta, setUrlPlanta] = useState("");
  const [desc, setDesc] = useState("");
  const [group, setNumber] = useState(5);
  const [brochure, Brochure] = useState("");
  const [urlRecorido, setRecorrido] = useState("");
  const [activo, setActivo] = useState("");
  let [selectdestination, setDestination] = useState(null);

  useEffect(() => {
    props.setIbiza(true);
    axios({
      method: "get",
      url: "https://panelcontrol.davivir.com.mx/prototiposlist/1",
    }).then(function (response) {
      getPrototipos(response.data);
      // console.log('data',response.data)
      if (window.screen.width < 550) {
        setNumber(1);
      } else {
        if (response.data.length >= 3) {
          setNumber(3);
        } else {
          setNumber(response.data.length);
        }
      }
      const activos = response.data.filter(
        (prototipo) => prototipo.prototipo_estado !== "agotado"
      );
      Brochure(
        `https://panelcontrol.davivir.com.mx${response.data[0].prototipo_urlBrochure}`
      );
      setRecorrido(`${response.data[0].prototipo_urlRecorrido}`);
      axios({
        method: "get",
        url: `https://panelcontrol.davivir.com.mx/galeriaslist/${activos[0].prototipo_id}`,
      }).then(function (response) {
        getGalerias(response.data);
        setUrlFachada(
          `https://panelcontrol.davivir.com.mx${activos[0].prototipo_urlFachada}`
        );
        setUrlPlanta(
          `https://panelcontrol.davivir.com.mx${activos[0].prototipo_urlPlanta}`
        );
        setDesc(activos[0].prototipo_descripcion);
        setDetalle(true);
        setTimeout(() => {
          props.setOculatr(false);
        }, 1000);
      });
    });
    return () => {
      props.setOculatr(true);
      // const helloboxWidghet = document.getElementById('hellobox-widget')
      // const helloboxBottomBar = document.getElementById('hellobox-bottom-bar')

      // if (helloboxWidghet && helloboxBottomBar) {
      //     helloboxBottomBar.remove();
      //     helloboxWidghet.remove();
      // }
    };
  }, []);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 550) {
        setNumber(1);
      } else {
        if (prototipos.length >= 3) {
          setNumber(3);
        } else if (prototipos.length > 0) {
          setNumber(prototipos.length);
        }
      }
    }
    window.addEventListener("resize", handleResize);
  });
  const getGaleria = (prototipo) => {
    if (prototipo.prototipo_estado !== "agotado") {
      axios({
        method: "get",
        url: `https://panelcontrol.davivir.com.mx/galeriaslist/${prototipo.prototipo_id}`,
      }).then(function (response) {
        getGalerias(response.data);
        setUrlFachada(
          `https://panelcontrol.davivir.com.mx${prototipo.prototipo_urlFachada}`
        );
        setUrlPlanta(
          `https://panelcontrol.davivir.com.mx${prototipo.prototipo_urlPlanta}`
        );
        setDesc(prototipo.prototipo_descripcion);
        Brochure(
          `https://panelcontrol.davivir.com.mx${prototipo.prototipo_urlBrochure}`
        );
        setDetalle(true);
        setRecorrido(`${prototipo.prototipo_urlRecorrido}`);
      });
    } else {
    }
  };
  return (
    <div>
      {/* <Helmet>
                <script  id="hbx_widget"  data-cfasync="false" type="text/javascript" src="https://hellobox.chat/dist/widget.min.js" data-token="34586d4b3844495756363957623256697a59564a51413d3d"  async="async"></script>
            </Helmet> */}
      <Whatsapp
        tel={
          "https://api.whatsapp.com/send/?phone=5215538938143&text&type=phone_number&app_absent=0"
        }
      ></Whatsapp>
      <Portada
        urlSlogan="./assets/imagenes/Ibiza/frase.png"
        urlPrototipo="./assets/imagenes/Ibiza/modelo-ibiza.png"
        urlEquipamiento="./assets/imagenes/Ibiza/equi-ibiza.png"
        urlAmenidad1="./assets/imagenes/Ibiza/aeropuerto.png"
        urlAmenidad2="./assets/imagenes/Ibiza/cdmx.png"
        urlAmenidad3="./assets/imagenes/Ibiza/autopista.png"
      />
      <Prototipos group={group}>
        {prototipos.map((val, index) => {
          return (
            <SwiperSlide key={index}>
              <div className="card" key={index} onClick={() => getGaleria(val)}>
                <img
                  key={index}
                  src={`https://panelcontrol.davivir.com.mx${val.prototipo_urlPortada}`}
                  alt="prototipo"
                />
              </div>
            </SwiperSlide>
          );
        })}
      </Prototipos>
      <CSSTransition
        in={showDetalle}
        timeout={200}
        classNames="deslizar"
        unmountOnExit
      >
        <DetallePrototipo
          correo={"ibiza"}
          setCorreo={props.setCorreo}
          showModal={props.showModal}
          setTipoModal={props.setTipoModal}
          setSubtitulo={props.setSubtitulo}
          showCarusel={props.showCarusel}
          setGaleria={props.setGaleria}
          galerias={galerias}
          urlfachada={urlfachada}
          urlPlanta={urlPlanta}
          desc={desc}
          urlBrochure={props.setBrochure}
          brochure={brochure}
          showRecorrido={props.showRecorrido}
          setUrlRecorrido={props.setUrlRecorrido}
          urlRecorido={urlRecorido}
          setShowSalesForce={props.setShowSalesForce}
        />
      </CSSTransition>
      <SalesForce
        showSegundoM={props.showSegundoM}
        showModal2={props.showModal2}
        nomodal={"nomodal"}
        titulo={"CONTÁCTANOS"}
      ></SalesForce>
      <Ubicacion
        mainFraccImg="./assets/imagenes/Ibiza/location/main-ibiza.png"
        locationImageRoute="./assets/imagenes/Ibiza/location/ubicacion1.png"
        logo="./assets/imagenes/Ibiza/location/LOGOBOSQUESIBIZA.png"
        mapIcon="./assets/imagenes/Ibiza/location/icono-mapa-ios.png"
        locationTextImg="./assets/imagenes/Ibiza/location/texto-ubicacion.png"
        peopleImg="./assets/imagenes/Ibiza/location/pareja.png"
        mapLinks={{
          googleMaps: "https://maps.app.goo.gl/uWuBPviqajRBH8xr9",
          waze: "https://www.waze.com/en/live-map/directions/mexico/hidalgo/ciudad-de-mexico/bosques-de-ibiza-davivir?place=ChIJHSVhscCQ0YURAl_R5CfN6-Q",
        }}
        address={
          <>
            {" "}
            <span>Carr. Pachuca-Mex km 57.5 1b</span>{" "}
            <span>Bosques de Ibiza C.P. 43815 </span>{" "}
            <span>Tizayuca, Hidalgo.</span>
          </>
        }
      />
    </div>
  );
};

export default Ibiza;
