import React from "react";
// Import Swiper React components
import { Swiper } from "swiper/react";
// Import Swiper styles
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";

import SwiperCore, { Autoplay, Pagination, Controller } from "swiper/core";

// install Swiper modules
SwiperCore.use([Pagination, Autoplay, Controller]);

const Slider = ({ children, nav, enableAutoplay = true, ...restProps }) => {
  return (
    <Swiper
      {...restProps}
      {...(enableAutoplay && {
        autoplay: {
          delay: 3500,
          disableOnInteraction: false,
        },
      })}
      spaceBetween={30}
      centeredSlides={true}
      loop={true}
      navigation={nav}
      className="mySwiper"
    >
      {children}
    </Swiper>
  );
};

export default Slider;
